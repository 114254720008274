import { createRouter, createWebHistory } from 'vue-router';
import store from './store'
const routes = [


  {
    path: "/",
    name: "Home",
    exact: true,
    component: () => import( "./pages/Login.vue" ),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/login",
    name: "Login",
    exact: true,
    component: () => import( "./pages/Login.vue" ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    exact: true,
    component: () => import( "./pages/ForgotPassword.vue" ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    component: () => import( "./pages/Dashboard.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/appuserlist",
    name: "AppUsersList",
    exact: true,
    component: () => import( "./pages/AppUsersList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systemuserlist",
    name: "SystemUsersList",
    exact: true,
    component: () => import( "./pages/SystemUsersList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/dashboard",
    name: "BillingDashboard",
    exact: true,
    component: () => import( "./pages/billing/Dashboard.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/statement",
    name: "AccountStatement",
    exact: true,
    component: () => import( "./pages/billing/AccountStatement.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/receipt",
    name: "PaymentReceipt",
    exact: true,
    component: () => import( "./pages/billing/PaymentReceipt.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/invoice",
    name: "TaxInvoice",
    exact: true,
    component: () => import( "./pages/billing/TaxInvoice.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/livebalance",
    name: "LiveBalances",
    exact: true,
    component: () => import( "./pages/billing/LiveBalances.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/ratecard",
    name: "RateCard",
    exact: true,
    component: () => import( "./pages/billing/RateCard.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/searchhistory",
    name: "SearchHistory",
    exact: true,
    component: () => import( "./pages/SearchHistory.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/new",
    name: "NewTicket",
    exact: true,
    component: () => import( "./pages/support/NewTicket.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/open",
    name: "OpenTicket",
    exact: true,
    component: () => import( "./pages/support/OpenTicket.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/closed",
    name: "ClosedTicket",
    exact: true,
    component: () => import( "./pages/support/ClosedTicket.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/cancelled",
    name: "CancelledTicket",
    exact: true,
    component: () => import( "./pages/support/CancelledTicket.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/total",
    name: "TotalTicket",
    exact: true,
    component: () => import( "./pages/support/TotalTicket.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/batch",
    name: "BatchSettings",
    exact: true,
    component: () => import( "./pages/settings/BatchSettings.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/rank",
    name: "RankSettings",
    exact: true,
    component: () => import( "./pages/settings/RankSettings.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/signupdomains",
    name: "SignupDomainsSettings",
    exact: true,
    component: () => import( "./pages/settings/SignupDomainsSettings.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/skiplist",
    name: "SkipListSettings",
    exact: true,
    component: () => import( "./pages/settings/SkipListSettings.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/directory",
    name: "DirectoryList",
    exact: true,
    component: () => import( "./pages/DirectoryList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/scamcenters",
    name: "ScamCenters",
    exact: true,
    component: () => import( "./pages/ScamCenters.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/aboutdeepsearch",
    name: "AboutDeepSearch",
    exact: true,
    component: () => import( "./pages/AboutDeepSearch.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/myprofile",
    name: "MyProfile",
    component: () => import( "./pages/MyProfile.vue" ),

    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)",
    name: "notfound",
    component: () => import( "./pages/NotFound" ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/underprogress",
    name: "underprogress",
    exact: true,
    component: () => import( "./pages/UnderProgress.vue" ),
    meta: {
      requiresAuth: true,
    },
  },

];

const router = createRouter( {
  history: createWebHistory( process.env.BASE_URL ),
  routes,
} );
router.beforeEach( async ( to, from, next ) => {
  await store.dispatch( "checkLoginCredentials" );
  const authenticatedUser = store.state.loggedInUserSessions.user_reference;
  const requiresAuth = to.matched.some( ( record ) => record.meta.requiresAuth );
  const checkModuleAccess = to.matched.some( ( record ) => record.meta.checkModuleAccess );
  const moduleId = to.meta.moduleId;
  const permission_fks = [ 1, 11, 12 ];

  if ( requiresAuth && !authenticatedUser ) {
    next( "/" );
  } else {
    if ( checkModuleAccess ) {
      if ( permission_fks.includes( moduleId ) ) {
        next();
      } else {
        next( false );
      }
    } else {
      next();
    }
  }
} );

export default router;